import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../components/layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import Hero from "../../components/hero/hero";
import VideoModal from "../../components/video-modal/video-modal";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";

const HelpfulTips = () => {
  const title = "Online and Mobile Banking";
  const description = "Learn how to set up and use online and mobile banking at WaFd Bank";

  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(relativePath: { eq: "hero/money-tips/hero-money-tips-02-2500.jpg" }) {
        ...heroImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/money-tips/hero-money-tips-02-1920.jpg" }) {
        ...heroImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/money-tips/hero-money-tips-02-1200.jpg" }) {
        ...heroImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/money-tips/hero-money-tips-02-992.jpg" }) {
        ...heroImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/money-tips/hero-money-tips-02-768.jpg" }) {
        ...heroImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/money-tips/hero-money-tips-02-600.jpg" }) {
        ...heroImageFragmentXS
      }
      card1: file(relativePath: { eq: "cards/how-to-send-zelle-payments-card-600.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      card2: file(relativePath: { eq: "cards/how-to-print-and-download-statements-card-600.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      card3: file(relativePath: { eq: "cards/how-to-make-mobile-deposit-card-600.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const heroData = {
    id: "wafd-support-hero",
    ...getHeroImgVariables(imgData, ["xs", "sm", "md", "lg", "xl", "xxl"]),
    altText: "Man using WaFd Bank mobile banking app on his smart phone",
    heroBody: {
      components: [
        {
          id: "hero-heading-01",
          heading: {
            text: "WaFd Helpful Tips"
          }
        },
        {
          id: 2,
          subheading: {
            text: "See how easy it is to start banking digitally with our Mobile Banking app and Online Banking."
          }
        }
      ]
    }
  };

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/support/helpful-tips"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/about-us/contact-us",
      title: "Support"
    },
    {
      id: 1,
      active: true,
      title: "Helpful Videos"
    }
  ];

  const [showVideo1, setShowVideo1] = useState(false);
  const handleCloseVideo1 = () => setShowVideo1(false);
  const handleShowVideo1 = () => {
    setShowVideo1(true);
  };

  const [showVideo2, setShowVideo2] = useState(false);
  const handleCloseVideo2 = () => setShowVideo2(false);
  const handleShowVideo2 = () => {
    setShowVideo2(true);
  };

  const [showVideo3, setShowVideo3] = useState(false);
  const handleCloseVideo3 = () => setShowVideo3(false);
  const handleShowVideo3 = () => {
    setShowVideo3(true);
  };

  const video1Data = {
    id: "zelle-payments-video",
    frameClass: "w-100 h-100",
    VimeoID: "657955080",
    Title: "How to Send and Receive Zelle Payments | WaFd Bank",
    modalSize: "lg",
    show: showVideo1,
    handleClose: handleCloseVideo1
  };

  const video2Data = {
    id: "print-statement-video",
    frameClass: "w-100 h-100",
    VimeoID: "657934068",
    Title: "How to Download and Print Your WaFd Bank Statement",
    modalSize: "lg",
    show: showVideo2,
    handleClose: handleCloseVideo2
  };

  const video3Data = {
    id: "mobile-deposit-video",
    frameClass: "w-100 h-100",
    VimeoID: "657963420",
    Title: "How to Make a Mobile Deposit on the WaFd Bank App",
    modalSize: "lg",
    show: showVideo3,
    handleClose: handleCloseVideo3
  };

  const cardData = {
    cards: [
      {
        id: "video-card-1",
        title: "Transfer Money with Zelle",
        onClick: handleShowVideo1,
        text: "Send money to friends and family or split a bill.",
        image: {
          imgFixed: false,
          imgVariable: imgData.card1.childImageSharp.gatsbyImageData,
          altText: "WaFd Bank Online Banking Zelle Money Transfer"
        }
      },
      {
        id: "video-card-2",
        title: "Print Statements",
        text: "Download or Print your WaFd Statements",
        onClick: handleShowVideo2,
        image: {
          imgFixed: false,
          imgVariable: imgData.card2.childImageSharp.gatsbyImageData,
          altText: "WaFd Bank Online Banking Download Statements"
        }
      },
      {
        id: "video-card-3",
        title: "Mobile Deposit",
        text: "Fast and simple deposits - no ATM or branch visit required",
        onClick: handleShowVideo3,
        image: {
          imgFixed: false,
          imgVariable: imgData.card3.childImageSharp.gatsbyImageData,
          altText: "WaFd Bank Online Banking Money Deposit"
        }
      }
    ]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Hero {...heroData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container text-center">
        <h1>WaFd Helpful Tips</h1>
        <p>Our how-to videos help you get things done</p>
      </section>
      <section className="container text-center">
        <h2>Online Banking</h2>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
          {cardData.cards.map((card) => (
            <div key={card.id} className="col mb-4">
              <div className="card h-100 position-relative shadow">
                {card.image && card.image.imgVariable && (
                  <GatsbyImage image={card.image.imgVariable} alt={card.image.altText} className="card-image-top" />
                )}
                <div className="card-body d-flex flex-column align-items-start">
                  {card.title && <h3 className="card-title">{card.title}</h3>}
                  {card.text && <p className="card-text text-left">{card.text}</p>}
                </div>
                {card.onClick && (
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      card.onClick();
                    }}
                    href="/#"
                    className="stretched-link"
                  >
                    <span className="sr-only">{card - title}</span>
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
        <Link to="/personal-banking/online-banking" className="btn btn-link my-4">
          Learn More about Online Banking
        </Link>
      </section>
      <section className="bg-alt">
        <div className="container text-center">
          <h2>Have Questions?</h2>
          <a href="tel: 800-324-9375" className="btn btn-primary">
            Call 800-324-9375
          </a>
        </div>
      </section>
      <VideoModal {...video1Data} />
      <VideoModal {...video2Data} />
      <VideoModal {...video3Data} />
    </SecondaryLanding>
  );
};

export default HelpfulTips;
